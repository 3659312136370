///////////////////
//////////////////////////////////////////////////////
/////////

// Components
import Communication from 'editor/managers/communication'
import imports from 'editor/runtime-imports'
import Features from 'editor/managers/features'
import SiteController from 'editor/controllers/readonly/site-controller'
// import SmoothScroll from 'editor/utils/smooth-scroll'
import AppMarketIntegration from 'shared/appmarket-integration'
import UrlHelper from '@eig-builder/core-utils/helpers/url-helper'

// Import Polyfills.
import 'intersection-observer'
import CrossBrowserPolyfill from 'cross-browser-polyfill'
import ObjectFitPolyfillerRuntime from './polyfills/object-fit-polyfiller-runtime'

// Misc
import './main.scss'
import './loader.scss'

import 'core/style/bootstrap/bootstrap.scss'
import 'core/style/font-awesome.scss'

// Polyfill registration.
if (window) {
  CrossBrowserPolyfill()
  ObjectFitPolyfillerRuntime()
}

// vars
let controller
let previousSelectedUri

const makeElement = (tagName, content, returnChildren = false) => {
  const element = document.createElement(tagName)

  if (tagName === 'style') {
    element.type = 'text/css'
  }

  element.innerHTML = content

  if (returnChildren) {
    return element.children
  }

  return element
}

const bindAll = (siteData, activePage, routeParams) => {
  if (siteData.partnerId !== undefined && siteData.siteId) {
    if (siteData.partnerId >= 100) {
      let hostingUrl = ''

      if (siteData.environment) {
        if (siteData.environment === 'dev') {
          hostingUrl = `https://hostingapi.latest.mywebsitebuilder.com`
        } else if (siteData.environment === 'prod') {
          hostingUrl = `https://hostingapi.mywebsitebuilder.com`
        } else {
          hostingUrl = `https://hostingapi.${siteData.environment}.mywebsitebuilder.com`
        }
      } else {
        // This is in preview
        hostingUrl = UrlHelper.getUrl(UrlHelper.siteNames.HOSTING_API, '')
      }

      AppMarketIntegration.load(`${hostingUrl}/v1.0/runtime/appmarket/render/${siteData.partnerId}/${siteData.siteId}`)
    } else {
      const path = `v1.0/runtime/appmarket/render/${siteData.partnerId}/${siteData.siteId}`
      const url = `${siteData.hostingPublishedDomain || ''}`
      AppMarketIntegration.load(url + (url.endsWith('/') ? '' : '/') + path)
    }

    if (!siteData.isScreenshot && window._page) {
      // insights
      AppMarketIntegration.initInsights()

      setTimeout(() => {
        // defer probably not needed but doesn't hurt eigther
        AppMarketIntegration.trackPageView(window._page.title)
      }, 1)
    }
  }

  Features.killAll()

  controller = new SiteController(imports, null, siteData, null)
  if (activePage) {
    controller.selectedPage = activePage
  }
  controller.previousSelectedUri = previousSelectedUri

  // controller.routeParams = routeParams
  Features.loadFeaturesRuntime(controller, routeParams)

  controller.bindAllElements(document.querySelector('.kv-site'))
  // SmoothScroll.start()
}

const bindAllForPreview = (siteData, activePage, routeParam) => {
  if (typeof window !== 'undefined') {
    window._site = siteData
  }

  bindAll(siteData, activePage, routeParam)
  // AppMarketIntegration.load(6, siteData.siteId || 100) // temp test for appmarket in preview
}

const bindAllForPublished = siteData => {
  let routeParams = []

  let uri = ''
  if (window._page) {
    uri = window._page.uriPath

    // figur out what there is in the url after the matching page uri
    // similar to: utils/decodeLocationToUriPathAndParams
    const loc = document.location.pathname
    const matchIndex = loc.indexOf('/' + window._page.uriPath)
    if (matchIndex >= 0) {
      routeParams = loc.substr(matchIndex + window._page.uriPath.length + 2).split('/')
    }
  } else {
    // backwards compatible (probably not needed, only for old published sites)
    const index = window.location.pathname.lastIndexOf('/')
    if (index >= 0) {
      uri = window.location.pathname.substr(index + 1)
    }
  }

  bindAll(siteData, uri, routeParams)
}

// hook where preview is loaded
const setSiteBody = ({ content, activePage, routeParam }) => {
  document.body.querySelectorAll('body > *:not(.pswp):not(iframe)').forEach(e => e.parentNode.removeChild(e))

  const styleElement = makeElement('style', content.style)
  document.body.appendChild(styleElement)

  if (content.script) {
    const scriptElement = makeElement('script', content.script)
    document.body.appendChild(scriptElement)
  }

  if (content.scriptUrls) {
    content.scriptUrls.forEach(s => {
      const scriptElement = makeElement('script', '')
      scriptElement.setAttribute('src', s)
      document.body.appendChild(scriptElement)
    })
  }

  const loadBody = () => {
    const bodyElements = makeElement('div', content.html, true)
    document.body.insertBefore(bodyElements[0], document.body.children[0])

    if (controller) {
      previousSelectedUri = controller.selectedPage
      controller.dispose()
    }

    window.setTimeout(() => {
      bindAllForPreview(content.site, activePage, routeParam)
      Communication.send('SITE_LOADED')
    }, 0)
  }

  // Wait for scripts to load
  if ((window.location.search || '').includes('scripts=') && content.scriptUrls) {
    setTimeout(loadBody, 1000)
  } else {
    loadBody()
  }
}

const setIframeHeight = ({ vh, vw }) => {
  window.setTimeout(() => {
    // Make sure kv-full-page works for iPads.
    document
      .querySelector('html')
      .setAttribute('style', 'height: 100%;overflow: auto;-webkit-overflow-scrolling: touch;')
    document
      .querySelector('body')
      .setAttribute('style', 'height: 100%;overflow: auto;-webkit-overflow-scrolling: touch;')
    document.querySelectorAll('.kv-full-page').forEach(element => {
      if (vh) {
        element.setAttribute(
          'style',
          `min-height: ${vh}px !important; height: ${vh}px !important; width: ${vw}px !important;`
        )
      }
    })
  }, 1)
}

if (window.self !== window.top) {
  // from iframe inline
  Communication.on(setSiteBody, 'SET_PUBLISHED_SITE_BODY')
  Communication.on(setIframeHeight, 'UPDATE_IFRAME_HEIGHT')
  Communication.send('loaded-editor', null)
}

function bindSite () {
  // attach controllers for pre-rendered site
  if (document.querySelector('.kv-site') && !controller) {
    if (window._site) {
      bindAllForPublished(window._site)
    } else {
      setTimeout(bindSite, 50) // could happen in preview
    }
  }
}

// window.addEventListener('load', () => {
window.setTimeout(bindSite, 0)
// })
