import UrlHelper from '@eig-builder/core-utils/helpers/url-helper'

const combineUrl = (path, params, app) => {
  const baseUrl = UrlHelper.api(path, app)
  return params
    ? `${baseUrl}${path.indexOf('?') === -1 ? '?' : '&'}${UrlHelper.param(params)}`
    : baseUrl
}

const ceApiUrl = (path, param) => UrlHelper.getUrl(UrlHelper.siteNames.CE_API, `/v1.0/${path}`, null, param)
const reApiUrl = (path, param) => combineUrl(`v1.0/${path}`, param, 'express-editor')
export const reApiV2Url = (path, param) => combineUrl(`v2.0/${path}`, param, 'express-editor')
// const reApiUrl = (path, param) => {
//   const paramStr = param && Object.keys(param).map(key => `${key}=${param[key]}`).join('&')
//   let url = `http://localhost:5000/v1/${path}`
//   if (paramStr) {
//     url += '?' + paramStr
//   }
//   return url
// }

const cpApiUrl = (path, param) => UrlHelper.getUrl(UrlHelper.siteNames.API, `/v1.0/${path}`, null, param)

const crawlrUrl = (path, param) => reApiUrl(`onboarding/crawl/${path}`, param)

const eventsUrl = (path, param) => UrlHelper.getUrl(UrlHelper.siteNames.EVENTS, `/v1.0/${path}`, null, param)

const storageUrl = (path, param) => UrlHelper.getUrl('storage', `/v1.0/${path}`, null, param)

const blogViewerUrl = path => UrlHelper.getUrl(UrlHelper.siteNames.BLOG_VIEWER_API, `/v1.0/${path}`)

const logoApiUrl = (path, param) => combineUrl(`v1.0/${path}`, param, 'logos')

const billingApiUrl = (path, param) => combineUrl(`v1.0/${path}`, param, 'billing')

export { reApiUrl, ceApiUrl, cpApiUrl, crawlrUrl, eventsUrl, storageUrl, logoApiUrl, blogViewerUrl, billingApiUrl }
