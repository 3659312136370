import each from 'lodash/each'
import debounce from 'lodash/debounce'

import LiveStyleSheet from './live-style-sheet'
import StringStyleSheet from './string-style-sheet'

let tempSwapStyleSheets
let stylesheets = {}

const updateMediaQueriesWidthsDebounce = debounce(function (deltaWidth) {
  each(stylesheets, sheet => {
    sheet.updateMediaQueriesWidths(deltaWidth)
  })
}, 200)

class StyleLoader {
  static setCollectStyleSheetsForPublish (value) {
    // keep references of old stylesheets for publishing from the editor
    if (value) {
      tempSwapStyleSheets = stylesheets
      stylesheets = {}
    } else {
      stylesheets = tempSwapStyleSheets
      tempSwapStyleSheets = null
    }
    StyleLoader.collectStyleSheetsForPublish = value
  }

  static getAllStyleSheetsForPublish () {
    let result = ''
    const imports = []
    each(stylesheets, sheet => {
      result += sheet.getCss() + '\n'
    })
    each(stylesheets, sheet => {
      sheet.getImports().forEach(imp => {
        if (!imports.includes(imp)) {
          imports.push(imp)
        }
      })
    })
    return imports.join('\n') + result
  }

  static clearAllStyleSheetsForPublish () {
    tempSwapStyleSheets = stylesheets
    stylesheets = {}
  }

  static updateMediaQueriesWidths (currentWindowWidth, editorWidth) {
    const deltaWidth = currentWindowWidth - editorWidth
    updateMediaQueriesWidthsDebounce(deltaWidth)
  }

  static setCss (stylesheetKey, css, isBaseStyling, layout, colorModel, stylesheetCollector) {
    stylesheetCollector = stylesheetCollector || stylesheets
    const Class = StyleLoader.collectStyleSheetsForPublish ? StringStyleSheet : LiveStyleSheet
    stylesheetCollector[stylesheetKey] = new Class(stylesheetKey, css, isBaseStyling, layout, colorModel)
  }

  static removeCss (stylesheetKey) {
    if (stylesheets[stylesheetKey]) {
      stylesheets[stylesheetKey].dispose()
      delete stylesheets[stylesheetKey]
    }
  }

  static loadOrUpdateLayout (layout, colorModel) {
    const stylesheetKey = layout.id
    const stylesheetCollector = stylesheets[stylesheetKey] ? stylesheets : colorModel.stylesheetCollector || stylesheets

    if (!stylesheetCollector[stylesheetKey]) {
      StyleLoader.setCss(stylesheetKey, layout.css, layout.id === 'base', layout, colorModel, stylesheetCollector)
    } else {
      stylesheetCollector[stylesheetKey].parseColors(colorModel)
    }
  }
}

const getCssClass = layout => {
  return layout.id
}

export default StyleLoader

export { getCssClass }
