// import AMCropper from '@eig-builder/module-image-selector/helpers/amcropper'
import ImageProxyHelper from 'core/helpers/image-proxy-helper'
import LocalImageHelper from 'shared/local-image-helper'

const lastProp = obj => {
  const keys = Object.keys(obj)
  return obj[keys[keys.length - 1]]
}

const getImageSourceUrl = image => {
  return image.temp_value || image.value || image.url || lastProp(image.sizes || {})
}

/**
 *
 * @param {*} image - image
 * @param {*} size - size for the image on the cdn
 * @param {*} cdnType - the cdn to use for proxying images
 */
const getImageUrl = (image, width, cdnType = 'cloudflare') => {
  const url = getImageSourceUrl(image)
  if (!url) {
    if (image._localImage) {
      return LocalImageHelper.getImage(image._localImage)
    }
    return image.base64Image
  }

  // backwards compatibility for icons in images
  if (url.indexOf('fa-') === 0) {
    return url
  }

  // try to read image from local cache (when the image is just oploaded)
  const localImage = LocalImageHelper.getImage(url)
  if (localImage) {
    return localImage
  }

  if (url.indexOf('data:image/svg') === 0) {
    return url.replace(/"/g, '&quot;')
  }

  const toPrec = value => {
    return parseInt(value * 100000) / 1000
  }

  if (IS_MAIL) {
    // if (image.colorOverlay || image.crop) {
    const methods = []
    if (image.crop && image.crop.rect) {
      const { rect } = image.crop
      methods.push(`crop,${toPrec(rect.x)}%,${toPrec(rect.y)}%,${toPrec(rect.width)}%,${toPrec(rect.height)}%`)
    }

    methods.push(`%5Eresize,${parseInt(width)},5000`)
    if (!image.hackForDemo) {
      methods.push(`%5Ecrop,${parseInt(width)},${parseInt((width * 190) / 295)}`)
    }

    if (image.colorOverlay) {
      methods.push(`overlay,${image.colorOverlay.join(',')},${image.overlayOpacity}%`)
    }

    // this code should be placed in the imageproxy helper but it is inside a package
    // for the demo I place it inline here
    let result = `https://images-latest.builderservices.io/s/cdn/v1.0/i/m?url=${encodeURIComponent(url)}`

    if (methods.length) {
      result += `&methods=${methods.join('|')}`
    }
    return result
    // }
  }

  const imageProxyHelper = new ImageProxyHelper(url, cdnType)
  if (image.crop && image.crop.rect) {
    imageProxyHelper.crop(image.crop, image.originalSize)
  }
  imageProxyHelper.resizeToWidth(width)
  return imageProxyHelper.result()

  /* let max = 10000
  let maxKey
  Object.keys(images).forEach(key => {
    const imageSize = ~~key
    if (Math.abs(imageSize - size) < max) {
      max = Math.abs(imageSize - size)
      maxKey = key
    }
  })
  return images[maxKey] */
}

export { getImageUrl, getImageSourceUrl }
