import get from 'lodash/get'
import isString from 'lodash/isString'
import buildEditableNode from './build-editable-node'
import toggleIsOn from './toggle-helper'
import GlobalProps from 'modules/editor/controls/handlebars/global-props'
import { getHref } from '../utils'

const findBinding = (classList, data) => {
  if (classList.data) {
    return classList.data.root
  } else {
    return data.data.root
  }
}

const getValue = (binding, property) => {
  let value = get(binding, property)

  if (typeof value === 'number') {
    value = value + ''
  }
  if (typeof value !== 'string' || !toggleIsOn(binding, property)) {
    return ''
  }
  return value
}

const textHelper = (property, nodeName, classList, data) => {
  const binding = findBinding(classList, data)
  if (typeof classList === 'object') {
    classList = ''
  }

  let value = getValue(binding, property)

  if (value === '') {
    const isListItem = binding._controlType && binding._controlType === 'list-item'
    if (isListItem) {
      if (binding.parent && binding.parent.dataSource) {
        return ''
      }
      classList += ' medium-editor-placeholder'
      const toggle = binding._parent._parent._toggle
      if (toggle && toggle['_list-item']) {
        if (toggle['_list-item'][property] === false) {
          return ''
        }
      }
      return buildEditableNode(
        nodeName,
        `class="${classList}" data-type="text" data-placeholder="${GlobalProps.localize(
          'designer.editor.mediumEditor.placeholder'
        )}"`,
        '',
        property,
        true
      )
    } else {
      return ''
    }
  }

  // replace page id links with actual page links
  if (!GlobalProps.editable) {
    value = value.replace(/(href="page:([^"]+)")/gi, (a, b, pageId) => {
      const pageHref = getHref({ href: 'page:' + pageId }, true)
      return `data-href="${pageHref}" href="${pageHref}"`
    })
  }

  const isRemoteData =
    binding._parent &&
    (binding._parent.isRemoteList || binding.dataSource) /* hacky for readonly menu titles, check datasource property */

  const hasEditingDisabled = isString(classList) && classList.includes('kv-readonly')
  // === PLACEHOLDER
  return isRemoteData || hasEditingDisabled
    ? `<${nodeName} class="${classList}">${value}</${nodeName}>`
    : buildEditableNode(nodeName, `class="${classList}" data-type="text"`, value, property)
  // === /PLACEHOLDER
}

const textHelperHandlebars = (property, nodeName, classList, data) => {
  return `${nodeName.data.root[property]}
  <span
    data-property="${property}"
    data-editable="basic"
    style="display-none">
  </span>`
}

// const textHelperSiteName = (property, nodeName, classList, data) => {
//   let binding = findBinding(classList, data)
//   const value = getValue(binding, property)

//   return value === ''
//     ? value
//     : `
//   <${nodeName}
//     class="${classList}"
//     data-type="sitename"
//     data-property="${property}">${value}</${nodeName}>`
// }

export default {
  textHelper,
  // textHelperSiteName,
  textHelperHandlebars
}
