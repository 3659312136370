import each from 'lodash/each'
import find from 'lodash/find'

import StyleSheet from './style-sheet'

class LiveStyleSheet extends StyleSheet {
  constructor (stylesheetKey, css, isBaseStyling, layout, colorModel) {
    super(layout, isBaseStyling)
    this.stylesheetKey = stylesheetKey

    this.imports = []

    if (layout && layout.imports) {
      layout.imports.forEach(imp => this.imports.push(imp))
    }

    if (this.imports.length > 0) {
      css = this.imports.join('\n') + css
    }

    this.makeNode(stylesheetKey, css)

    if (colorModel && colorModel.siteController.editorContext) {
      // Fix for the fixed elements in templates inside the editor
      each(this.styleSheet.cssRules, rule => {
        if (rule.style && rule.style.position === 'fixed') {
          rule.style.position = 'absolute'
        }

        if (rule.cssRules) {
          each(rule.cssRules, childRule => {
            if (childRule.style && childRule.style.position === 'fixed') {
              childRule.style.position = 'absolute'
            }
          })
        }
      })
    }

    if (layout && layout.style_variables) {
      this.parseColors(colorModel)
    }
  }

  makeNode (stylesheetKey, css) {
    const element = document.createElement('style')
    element.setAttribute('data-id', stylesheetKey)
    element.type = 'text/css'
    element.innerHTML = css
    document.querySelector('head').appendChild(element)

    this.styleSheet = element.sheet
  }

  updateMediaQueriesWidths (deltaWidth) {
    if (this.stylesheetKey === 'fontPairStyle') {
      // breaks under FF
      return
    }
    const rules = this.styleSheet.cssRules
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].conditionText) {
        const theRule = rules[i]
        let mediaQ = theRule.originalConditionText || theRule.conditionText
        theRule.originalConditionText = mediaQ

        mediaQ = mediaQ.replace(/\(([min|max]+)-width: (\d+)px\)/gi, (line, minMax, size) =>
          line.replace(size, Math.max(1, ~~size + deltaWidth))
        )

        if (theRule.media) {
          theRule.media.mediaText = mediaQ
        }
      }
    }
  }

  dispose () {
    this.styleSheet.ownerNode.remove() // remove from dom
  }

  setCssRule (conditionText, styleDeclaration, selector, value, rawColor) {
    // counter++
    // if (!this.timeout) {
    //   this.timeout = setTimeout(() => {
    //     if (counter > 0) {
    //       console.log('rules written: ' + counter)
    //       counter = 0
    //     }
    //     this.timeout = null
    //   }, 1)
    // }
    let rule

    if (conditionText) {
      const rules = this.styleSheet.cssRules
      let i = 0
      // search through media rules to find the correct sub-rule
      while (!rule && i < rules.length) {
        const mediaRule = rules[i++]
        if (mediaRule.conditionText === conditionText) {
          rule = find(mediaRule.cssRules, r => r.selectorText === selector)
        }
      }
    } else {
      rule = find(this.styleSheet.cssRules, r => r.selectorText === selector)
    }

    if (rule) {
      // for future debug purposes
      // console.log('update %c' + selector + ': ' + styleDeclaration + ': ' + value, 'background: ' + ((rawColor[0] + rawColor[1] + rawColor[2] > 128 * 3 ? '#000' : '#fff')) + '; color: ' + value)
      rule.style[styleDeclaration] = value
    } else {
      // console.log('insert %c' + selector + ': ' + styleDeclaration + ': ' + value, 'background: ' + ((rawColor[0] + rawColor[1] + rawColor[2] > 128 * 3 ? '#000' : '#fff')) + '; color: ' + value)
      const index = this.styleSheet.cssRules.length

      const theRule = `${styleDeclaration}: ${value}`
      if (this.styleSheet.insertRule) {
        let ruleStr = `${selector} { ${theRule}; }`
        if (conditionText) {
          ruleStr = `@media ${conditionText} { ${ruleStr} }`
        }
        this.styleSheet.insertRule(ruleStr, index || 0)
      } else {
        if (this.styleSheet.addRule) {
          this.styleSheet.addRule(selector, theRule, index)
        }
      }
    }
  }
}

export default LiveStyleSheet
