import { pickImage, pickUrl } from '../../../utils/image-size-helper'
import { getHref } from '../utils'
import GlobalProps from 'editor/controls/handlebars/global-props'

const imageHelper = (prop, property, nodeName, classList, data, controlType, dataType = 'image') => {
  const binding = data.data.root
  const { link, altText, width, height, logoSizeClass, crop, parallax } = prop

  let outerAttributes = `data-type="${dataType}" data-property=${property}`

  const anchorStyle = 'width: 100%; height: 100%; display: block; position: absolute; top: 0; left: 0;z-index: 1'
  const linkHref = getHref(link)

  let anchorTag = ''
  if (dataType === 'logo') {
    outerAttributes += ` class="${logoSizeClass || 'logo-medium'}"`
    anchorTag = `<a href="/" data-href="/" style="${anchorStyle}"></a>`
  } else if (linkHref) {
    anchorTag = `<a href="${linkHref}" data-href="${linkHref}" style="${anchorStyle}"
      ${link.shouldOpenInTab ? 'target="_blank"' : ''}
      ></a>`
  }

  const lowResScale = 0.1

  const imgValue = pickUrl(prop, controlType)
  const imgValueRetina = pickUrl(prop, controlType, true)
  const imgValueLowres = dataType !== 'logo' ? pickUrl(prop, controlType, null, lowResScale) : imgValue
  if (!imgValue) {
    return ''
  }

  // some code for facebook datasource stuff

  let styles = ''
  if (height && width) {
    const ratio = (height / width) * 100
    styles += `background-size:cover;padding-top:${ratio}%;`
  }

  if (crop) {
    if (crop.offsetX !== undefined) {
      styles += `background-position-x:${crop.offsetX * 100}%;`
    }
    if (crop.offsetY !== undefined) {
      styles += `background-position-y:${crop.offsetY * 100}%;`
    }
  } else {
    styles += `background-position: center center;`
  }

  // Dont lazy load first 2 sections for Google Pagespeed score.
  const useLazyLoad = binding._index >= 2 && !GlobalProps._loadedImages[imgValue]

  let innerAttributes = parallax
    ? ` data-parallax="${parallax}" class="${classList}"`
    : ` class="${classList} ${useLazyLoad ? 'loader' : ''}"`

  if (nodeName.match(/img/i)) {
    if (altText) {
      innerAttributes += `alt="${altText}"`
    }
    return `<div ${outerAttributes}>
      <img      
      ${innerAttributes}
      src="${imgValue}" /> ${anchorTag} </div>`
  } else {
    if (!useLazyLoad) {
      styles += pickImage(prop, controlType, null)
    } else {
      // set data attributes for blur-in-images-mixin (lazy loading images)
      innerAttributes += ` role="img" data-src="${imgValueLowres}" data-src-normal="${imgValue}" data-src-retina="${imgValueRetina}"`
    }
    if (altText) {
      innerAttributes += ` aria-label="${altText}"`
    }

    return `<${nodeName}
      ${outerAttributes}
      ${innerAttributes}
      style="${styles}"
    >${anchorTag}</${nodeName}>`
  }
}

export default imageHelper
