import BaseController from '../base-controller'
import Dictionary from '../../utils/dictionary'
import LayoutHelper from '../../utils/layout-helper'

import { SECTION_STATUS } from 'shared/enums'

class ListController extends BaseController {
  constructor (parentController, model) {
    super(parentController, model)

    const sectionModel = this.getSectionController().model

    this.controlDictionary = new Dictionary(this.createListItemControl)

    let controlType
    if (sectionModel.binding.remoteSource && this.isRuntime()) {
      this.updateListsWithRemoteValue()
    }
    if (sectionModel.layout.listItem && this.isRuntime() && sectionModel.category === 'images') {
      controlType = 'gallery'
    } else {
      controlType = ''
    }

    this.control = new this.imports.ListControl(this, controlType)

    this.listItemController = new this.imports.ListItemController(this)
  }

  updateListsWithRemoteValue = () => {
    const siteController = this.getSiteController()
    const model = this.getContentController().model
    const remoteSource = model.remoteSource
    const type = remoteSource.type ? remoteSource.type.toLowerCase() : remoteSource
    let account

    const inSync = { value: true }

    if (this.imports.DataProxyHelper) {
      if (this.isPublishedSite() && !model.requestBody) {
        throw new Error('no requestbody found for this site')
      }

      if (this.getSectionController().isForPreview()) {
        // Use demo data in preview mode
        this.remoteList = this.model
      } else {
        account = siteController.getAccountForRemoteSource(remoteSource)
        if (!account) {
          return
        }
        this.imports.DataProxyHelper.getData(
          account,
          type,
          remoteSource.path,
          (result, requestBody) => {
            this.remoteList = result

            if (!inSync.value) {
              this.controlDictionary.clear() // don't leak all the old controls
              this.control.render()
            }
          },
          this.isRuntime() && model.requestBody,
          siteController.model.urls['dataproxy'],
          siteController.model.urls['re_api']
        )
      }
    } else if (this.imports.isPublisher) {
      if (
        this.imports.DataProxyPublisher &&
        siteController.publisher &&
        Array.isArray(siteController.publisher.dataCalls)
      ) {
        siteController.publisher.dataCalls.push(
          this.imports.DataProxyPublisher.getDataCall(account, remoteSource, model)
        )
      }
    }
    inSync.value = false

    this.dataSource = {
      path: remoteSource.path,
      type,
      account
    }
  }

  createListItemControl = dataItem => {
    const control = new this.imports.ListItemControl(
      this.listItemController,
      (parentBinding, index, model) => {
        let templateName

        if (model && model._type === 'header' && model.label === 'Map') {
          return
        }
        if (parentBinding && parentBinding._columns === 1 && parentBinding._rowLayout !== undefined) {
          templateName = parentBinding._rowLayout
          if (templateName === 'alternating') {
            templateName = (index + 1) % 2 === 1 ? 'left' : 'right'
          }
        }
        if (model && model._type === 'header') {
          templateName = 'header'
        }
        const result = this.getSectionController().getListItemLayout(templateName)
        return result
      },
      dataItem
    )
    return control
  }

  onAfterRender = () => {
    if (this.imports.isPublisher) {
      const layoutProp = this.getSectionController().getLayoutModel()
      const itemLayout = LayoutHelper.getLayoutObj(this.getSectionController().getLayoutModel(), 'listItem')
      layoutProp.listItem = itemLayout
      delete itemLayout.compiledLayouts
    }
  }

  getColumnClassNames (columns, mobileWidth = 12) {
    if (columns === undefined) {
      return null
    }
    let className = `col-${mobileWidth} col-lg-${12 / columns}`

    if (columns === 3) {
      className += ' col-md-6'
    }

    if (columns === 4) {
      className += ' col-md-4'
    }

    if (columns === 6) {
      className += ' col-md-4 col-sm-6'
    }
    return className
  }

  internalGetValue (model) {
    const contentModel = this.getContentController().model
    if (contentModel.remoteSource) {
      this.updateListsWithRemoteValue()
    }

    const sectionId = this.getSectionController().model.id
    const layoutSettings = this.getSectionController().getLayoutModel()
    const binding = this.getContentController().model
    const isRemoteList = !!binding.remoteSource
    const listType = isRemoteList && binding.remoteSource.type
    const _columns = layoutSettings.columns || 4

    return {
      isRemoteList,
      listType,
      _columns,
      _rowClassNames: this.getColumnClassNames(_columns, binding.mobileColumns ? 12 / binding.mobileColumns : 12),
      _rowLayout: layoutSettings.listItemAlign,
      _sectionId: sectionId
    }
  }

  getValue = model => {
    return this.internalGetValue(model)
  }

  getList () {
    const contentController = this.getContentController()
    const value = contentController.getValue()

    if (value.listCollection) {
      return value.listCollection[this.control._index].items
    }

    // return list from the feature render
    const featureModel = contentController.getFeatureRenderModel()
    if (featureModel && featureModel.list) {
      this.hasDeleteButton = false // sort of hack for the store feature, maybe we want control like this from the feature, but that would be a bit more work
      return featureModel.list
    }

    if (contentController.model.remoteSource) {
      if (contentController.model.remoteSource.type === 'facebook') {
        return (this.remoteList || []).slice(0, this.control.binding._columns !== 4 ? 6 : 8)
      }

      return this.remoteList || []
    }

    const data = contentController.getDataFromDatasource()
    if (data) {
      return data.items
    }

    for (let i = 0; i < this.model.length; i++) {
      this.model[i].__index = i
    }
    return this.model
  }

  getChildControls () {
    const list = this.getList()

    const isRemoteSource = this.parentController.model.remoteSource
    const pageSize = this.parentController.model.pageSize
    if (isRemoteSource && pageSize) {
      return this.controlDictionary.mapWithCache(list.slice(0, pageSize))
    } else {
      if (this.status === SECTION_STATUS.SECTION_PREVIEW) {
        // Only render 10 items in section preview
        const MAX_LIST_ITEMS = 10
        return this.controlDictionary.mapWithCache(list.slice(0, MAX_LIST_ITEMS))
      }
    }

    return this.controlDictionary.mapWithCache(list)
  }
}

export default ListController
