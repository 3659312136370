import RuntimeConfigHelper from '@eig-builder/core-utils/helpers/runtime-config-helper'
import Store from '@eig-builder/core-utils/store'

export const SINGLE_PLATFORM = 'singleplatform'
export const CONSTANT_CONTACT = 'constantcontact'
export const BUILDER_PLATFORM = 'builder'
export const ENDURANCE_PLATFORM = 'endurance'

const validateAccount = (accountType, typeToCheck) => {
  return accountType && accountType.toLowerCase() === typeToCheck
}

const usesLoginToken = authenticationMethod => {
  return authenticationMethod && authenticationMethod.toLowerCase() === 'logintoken'
}

export const isDIFM = accountType => {
  return isSP(accountType) || isCTCT(accountType)
}

export const isSP = accountType => {
  return validateAccount(accountType, SINGLE_PLATFORM)
}

export const isSPAgent = (accountType, authenticationMethod) => {
  return isSP(accountType) && usesLoginToken(authenticationMethod)
}

export const isCTCT = accountType => {
  return validateAccount(accountType, CONSTANT_CONTACT)
}

export const isCTCTAgent = (accountType, authenticationMethod) => {
  return isCTCT(accountType) && usesLoginToken(authenticationMethod)
}

export const isBuilder = accountType => {
  return validateAccount(accountType, BUILDER_PLATFORM)
}

export const isHostedBrand = () => {
  return RuntimeConfigHelper.getRuntimeConfig()._brand.toLowerCase() === ENDURANCE_PLATFORM
}

export const isCTCTBrand = () => {
  return RuntimeConfigHelper.getRuntimeConfig()._brand.toLowerCase() === CONSTANT_CONTACT
}

export const isSupportAgent = (accountType, authenticationMethod) => {
  return isSPAgent(accountType, authenticationMethod) || isCTCTAgent(accountType, authenticationMethod)
}

export const isWhiteListedAccount = auth => {
  return auth && auth.metadata && (auth.metadata.is_whitelisted_ip || auth.metadata.is_whitelisted_email)
}

export const isEIGPlatform = accountType => {
  return isBuilder(accountType) || isHostedBrand()
}

export const isLoggedInAccount = auth => {
  auth = auth || Store().getState().auth
  return auth && auth.isAuthenticated
}

export const isWithoutAccountFlow = () => {
  const { auth } = Store().getState()
  return !auth || !auth.accountId
}
