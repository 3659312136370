import UrlHelper from '@eig-builder/core-utils/helpers//url-helper'
// import { getResolution } from '@eig-builder/core-utils/helpers//screen-helper'
import RuntimeConfigHelper from '@eig-builder/core-utils/helpers//runtime-config-helper'

// const CMD_RESIZE = 's'
// const CMD_CROP = 'c'
const IMPRESSLY_BASE_URL = 'impress.ly'
const MYWEBSITEBUILDER_BASE_URL = 'images.builderservices.io'
const MYWEBSITEBUILDER_BASE_URL_LATEST = 'images-latest.builderservices.io'
const UNSPLASH_DOMAIN = 'images.unsplash.com'

// Refactor Remove the Impressly Image proxy
class ImageProxyHelper {
  constructor (src, cdnType) {
    this.src = ImageProxyHelper.stripProxyUrl(src)
    const unsplashDomainIndex = src.indexOf(UNSPLASH_DOMAIN)
    this.useUnsplash = unsplashDomainIndex < 10 && unsplashDomainIndex >= 0
    this.cdnType = cdnType
    // this.modifiers = []
    this.proxyModifiers = []
    return this
  }

  _proxyHash = value => {
    if (typeof value !== 'string' || value === '') {
      return 0
    }
    value = value.toLowerCase()
    let c = 0
    for (let i = 0; i < value.length; i++) {
      c ^= value.charCodeAt(i)
    }
    return c % 10
  }

  calcCropRect (rect, size) {
    return [
      Math.round(rect.x * size.width),
      Math.round(rect.y * size.height),
      Math.round(rect.width * size.width),
      Math.round(rect.height * size.height)
    ]
  }

  crop = (crop, originalSize) => {
    if (this.useUnsplash) {
      // resize to a bigger image ()
      if (crop.imageSize.width && crop.imageSize.height) {
        this.src = this.modifiyUnsplashUrl(this.src, crop.imageSize.width, crop.imageSize.height)
      }
      this.useUnsplash = false // crop at our own server
    }
    // if (originalSize) {
    const { x, y, width, height } = crop.rect
    const toPercentage = val => Math.abs(val * 100).toFixed(4) // from normal to percentage with 4 decimals
    if (height > 0 && width > 0) {
      // con't be able to crop when height or width is zero
      this.proxyModifiers.push(
        `crop,${toPercentage(x)}%,${toPercentage(y)}%,${toPercentage(width)}%,${toPercentage(height)}%`
      )
    }
    return this
    // } else {
    //   // we don't know the original size of the image so first load the cropped (down sized) version of the image and then crop at our own server
    //   if (crop.imageSize.width && crop.imageSize.height) {
    //     this.src = this.modifiyUnsplashUrl(this.src, crop.imageSize.width, crop.imageSize.height)
    //   }
    //   this.useUnsplash = false // let crop be done by our own server
    // }
    // }

    // if (originalSize || (crop.imageSize && crop.imageSize.width && crop.imageSize.height)) {
    //   const rect = this.calcCropRect(crop.rect, originalSize || crop.imageSize)
    //   this.modifiers.push(`${CMD_CROP}/${rect[2]}/${rect[3]}/${rect[0]}/${rect[1]}`)
    // }

    // return this
  }

  modifiyUnsplashUrl (url, width, height, cropRect) {
    if (!this.useUnsplash) {
      throw Error('no Unsplash image')
    }

    const params = UrlHelper.getQueryParametersFromUrl(url)
    const split = url.split('?')

    params.w = parseInt(width)

    return `${split[0]}?${UrlHelper.param(params)}`
  }

  resizeToWidth = width => {
    if (this.useUnsplash) {
      this.src = this.modifiyUnsplashUrl(this.src, width)
    } else {
      this.proxyModifiers.push(`resize,${parseInt(width)},5000`)
      // this.modifiers.push(`${CMD_ASPECT_RESIZE}/${width}x${width}/0`)
    }
  }

  // resize = (size = getResolution(), preserveAspect = true) => {
  //   this.modifiers.push(`${preserveAspect ? CMD_ASPECT_RESIZE : CMD_RESIZE}/${size}/0`)
  //   return this
  // }

  /**
   * Return the computed imageURL to the original callee.
   */
  result = () => {
    let baseUrl
    if (this.cdnType === 'cloudflareHosted') {
      baseUrl = '/s/cdn'
    } else {
      baseUrl = `https://${ImageProxyHelper.getBaseUrl()}/s/cdn`
    }

    let url = `${baseUrl}/v1.0/i/m?url=${encodeURIComponent(this.src)}`

    if (this.proxyModifiers.length > 0) {
      const methods = this.proxyModifiers.join('|')
      url += `&methods=${encodeURIComponent(methods)}`
    }

    return url
  }

  /**
   * Will change the provided source URL and will return it prefixed with the Image Proxy Url.
   * @param {string} source, the image that should be routed through the image proxy.
   */
  static getProxyUrl (source) {
    return UrlHelper.getUrl(UrlHelper.siteNames.IMAGE_PROXY, source)
  }

  static getBaseUrl = () => {
    const env = RuntimeConfigHelper.getRuntimeConfig()._env
    return !env || env === 'prod' || env === 'uat' ? MYWEBSITEBUILDER_BASE_URL : MYWEBSITEBUILDER_BASE_URL_LATEST
  }

  /**
   * Used to extract the true image URL when the helper is used on image proxy URLs.
   * @param {string} src URL to extract the original URL from
   */
  static stripProxyUrl (src) {
    const regex = /\/\/(.+)\/s\/cdn\/.*\?(?:url=)?([^&]+)/
    const match = src.match(regex)

    if (!match || (match[1] !== ImageProxyHelper.getBaseUrl() && match[1] !== IMPRESSLY_BASE_URL)) {
      return src
    }

    return decodeURIComponent(match[2])
  }
}

export default ImageProxyHelper
