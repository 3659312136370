import Modal from './modal'

class Modals {
  static idCounter = 0

  constructor () {
    this.modals = {}
  }

  showModal (opts) {
    const id = Modals.idCounter
    Modals.idCounter++
    const modal = new Modal(id, opts, this)
    this.modals[id] = modal
    document.body.appendChild(modal.domElement)

    setTimeout(() => {
      modal.setActive(true)
    }, 200)
  }

  closeModal (id, onClose) {
    const modal = this.modals[id]
    modal.setActive(false)

    setTimeout(() => {
      delete this.modals[id]

      if (onClose) {
        onClose(modal.domElement)
      }
      document.body.removeChild(modal.domElement)
    }, 200)
  }
}

export default Modals
