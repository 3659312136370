var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div data-type=\"remote-list\">\n    <div class=\"row\" >\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0._children : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../../../../services/app-designer/helpers/control.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.key),(data && data.index),depth0,{"name":"control","hash":{},"data":data}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"row\" data-type=\"list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0._children : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col-12 text-center add-container kv-control\">\n      <button class=\"kv-add-item-btn kv-editor-button large\">"
    + container.escapeExpression(__default(require("../../../../services/app-designer/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"_addItemLabel","span",depth0,false,{"name":"text","hash":{},"data":data}))
    + "</button>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div>\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? depth0.isRemoteList : depth0), depth0),{"name":"isRemoteList","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../services/app-designer/helpers/editable.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"editable","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});