import each from 'lodash/each'

// like dotnet dictionary, as key we use in memory objects
class Dictionary {
  constructor (createDelegate) {
    this.createDelegate = createDelegate
    this.clear()
  }

  clear () {
    this.keys = []
    this.values = []
  }

  hasKey (obj) {
    const index = this.keys.indexOf(obj)
    return index >= 0
  }

  set (key, value) {
    const index = this.keys.indexOf(key)
    if (index < 0) {
      this.keys.push(key)
      this.values.push(value)
    } else {
      this.values[index] = value
    }
  }

  get (key) {
    const index = this.keys.indexOf(key)
    if (index < 0) {
      return null
    } else {
      return this.values[index]
    }
  }

  getKeyForValue (value) {
    const index = this.values.indexOf(value)
    if (index < 0) {
      return
    }
    return this.keys[index]
  }

  getOrAdd (key) {
    const index = this.keys.indexOf(key)
    if (index < 0) {
      const value = this.createDelegate(key)
      this.keys.push(key)
      this.values.push(value)
      return value
    } else {
      return this.values[index]
    }
  }

  getOrAddEach (keys) {
    each(keys, key => this.getOrAdd(key))
  }

  // return values sorted by keys
  getValuesLike (keys) {
    return keys.map(key => this.get(key))
  }

  mapWithCache (keys) {
    this.getOrAddEach(keys)
    return this.getValuesLike(keys)
  }

  getValues () {
    return this.values
  }
}

export default Dictionary
