import Popovers from 'designer/components/popovers'
import { isAllowedOrUnlock, hasUpgrade } from '@eig-builder/core-utils/helpers/limitation-helper'

import Store from '@eig-builder/core-utils/store'
import { getLimitations } from 'designer/store/configuration/actions'
import { OPEN_UPGRADE_MODAL, CLOSE_UPGRADE_MODAL } from 'designer/store/constants'

let _limitations = {}
let _limitationsResponse = null
let _limitationsLoaded = false
let _limitationFocusListener

class LimitationsManager {
  static registerDispatch (dispatch) {
    LimitationsManager.dispatch = dispatch
  }

  static setLimitations (limitations) {
    if (!limitations) {
      limitations = {}
    }
    _limitations = limitations
    _limitationsLoaded = true
  }

  static getResponse () {
    return _limitationsResponse
  }

  static setResponse (limitationsResponse) {
    if (!limitationsResponse) {
      limitationsResponse = {}
    }
    _limitationsResponse = limitationsResponse
  }

  static canUpgrade (limitationKey) {
    if (_limitationsResponse === null) {
      return false
    }
    return hasUpgrade({
      limitationsResponse: _limitationsResponse,
      limitationKey: limitationKey
    })
  }

  static requestModal (config) {
    const reduxEventPayload = { key: config && config.key }
    Store().dispatch({ type: OPEN_UPGRADE_MODAL, payload: reduxEventPayload })
    const callback = config.callback || (() => {})
    const modalConfig = {
      limitationKey: config.key,
      limitationsResponse: _limitationsResponse || {},
      success: () => {
        LimitationsManager.updateLimitations()
        callback(null, true)
        Store().dispatch({ type: CLOSE_UPGRADE_MODAL, payload: { ...reduxEventPayload, success: true } })
      },
      successUrl: config.successUrl || window.location.href,
      errorUrl: config.errorUrl || window.location.href,
      error: err => {
        LimitationsManager.updateLimitations()
        callback(err, null)
        Store().dispatch({ type: CLOSE_UPGRADE_MODAL, payload: reduxEventPayload })
      },
      extraInfo: {
        purchaseOrigin: config.key ? `express-editor-upgrade-modal-${config.key}` : `express-editor-upgrade-button`
      }
    }

    if (config.value) {
      modalConfig.value = config.value
    }

    isAllowedOrUnlock(modalConfig)
  }

  static updateLimitations () {
    const siteId = Store().getState().api.siteId
    LimitationsManager.dispatch(getLimitations(siteId))
  }

  static openUpgradeDialog (limitName) {
    return new Promise((resolve, reject) => {
      const limit = _limitations[limitName]
      if (!limit) {
        reject(Error(`No limitation with name '${limitName}'`))
        return
      }

      // Open modal with options
      Popovers.activate({
        type: 'modal',
        content: 'upgrade-modal',
        data: {
          type: limitName,
          callback: resolve,
          error: reject
        }
      })
    })
  }

  static getLimitation (limitName) {
    if (!_limitationsLoaded) {
      return true
    }

    const limitation = _limitations[limitName]

    if (limitation && limitation.value) {
      return !!limitation.value
    }
    return false
  }

  static defaultValidate (limitName) {
    const limit = LimitationsManager.getLimitation(limitName)
    if (limit) {
      LimitationsManager.openUpgradeDialog(limitName)
      return false
    }
    return true
  }

  static defaultValidatePromise (limitName) {
    return new Promise((resolve, reject) => {
      const limit = LimitationsManager.getLimitation(limitName)
      if (!limit) {
        resolve({
          success: true,
          exists: false
        })
        return
      }
      LimitationsManager.openUpgradeDialog(limitName)
        .then(success =>
          resolve({
            success,
            exists: true
          })
        )
        .catch(e => reject(e))
    })
  }

  static openFileLimitDialog () {
    LimitationsManager.openUpgradeDialog('file')
  }

  static validateLimit (limitName) {
    return LimitationsManager.defaultValidate(limitName)
  }

  static validatePosts (postsCount) {
    const limitation = LimitationsManager.getLimitation('blog')
    return limitation
    // if (limitation) {
    //   if (postsCount >= limitation.maxPosts) {
    //     LimitationsManager.openUpgradeDialog('blog')
    //     return false
    //   }
    // }
    // return true
  }

  static validatePublish () {
    return LimitationsManager.defaultValidate('publish')
  }

  static validateVideo (totalVideos) {
    const limit = LimitationsManager.getLimitation('video')
    if (limit) {
      if (totalVideos !== undefined) {
        const count = limit.maxVideos || 1
        if (totalVideos < count) {
          return true
        }
      }
      LimitationsManager.openUpgradeDialog('video')
      return false
    }
    return true
  }

  static validatePages (totalPages) {
    const limitation = _limitations['sitePages']
    if (limitation && limitation.value && totalPages !== undefined) {
      const isTrue = limitation.value === true
      const isNumber = Number.isInteger(limitation.value)
      const isLimited = isTrue || (isNumber && totalPages >= limitation.value)

      if (isLimited) {
        LimitationsManager.openUpgradeDialog('sitePages')
      }
      return !isLimited
    }
    return true
  }

  static isVideoLimited () {
    return LimitationsManager.getLimitation('video')
  }

  static validateMobile () {
    return LimitationsManager.defaultValidate('mobile')
  }

  static validateHdVideo () {
    return LimitationsManager.defaultValidate('hdvideo')
  }

  /* Stats */
  static validateStats () {
    return LimitationsManager.defaultValidate('stats')
  }

  static isStatsLimited () {
    return LimitationsManager.getLimitation('stats')
  }

  static getStatsLimit () {
    return LimitationsManager.getLimitationNew('stats')
  }

  /* History */
  static validateHistory () {
    return LimitationsManager.defaultValidatePromise('history')
  }

  static getUpgradeUrl (limitName) {
    const limitation = _limitations[limitName]
    if (limitation && limitation.settings && limitation.settings.upgradeUrl) {
      return limitation.settings.upgradeUrl
    }
    return null
  }

  static getHistoryLimit () {
    return LimitationsManager.getLimitationNew('history')
  }

  /* Favicon */
  static validateFavicon () {
    return LimitationsManager.defaultValidate('favicon')
  }

  static isFaviconLimited () {
    return LimitationsManager.getLimitation('favicon')
  }

  /* Ecommerce */
  static validateEcommerce () {
    return LimitationsManager.defaultValidate('store')
  }

  static getEcommerceLimit () {
    return _limitations['store']
  }

  static isEcommerceLimited (totalProducts) {
    return LimitationsManager.getLimitation('store')
  }

  static isBlogLimited () {
    return LimitationsManager.getLimitation('blog')
  }

  static isFormLimited () {
    return LimitationsManager.getLimitation('form')
  }

  static isFileUploadLimited () {
    const limitation = LimitationsManager.getLimitation('file')
    return limitation.isLimited
  }

  static isEcommerceProductsLimited (totalProducts) {
    const limitation = LimitationsManager.getEcommerceLimit()
    if (limitation) {
      if (limitation.value) {
        return true
      }
      const productLimit = limitation.productLimit || 0
      const productCount = totalProducts || 0
      if (productLimit > 0 && productCount >= productLimit) {
        return true
      }
    }
    return false
  }

  static getPageLimit () {
    return _limitations.pageLimit || null
  }

  static addOnWindowFocusListener (configActions, siteId) {
    if (!_limitationFocusListener) {
      _limitationFocusListener = () => {
        configActions.getLimitations(siteId)
      }
      window.addEventListener('focus', _limitationFocusListener)
    }
  }
}

export default LimitationsManager
