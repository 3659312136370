import each from 'lodash/each'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import every from 'lodash/every'

////////////////
///////////////////////////////////////////
////////////////////////////////
///////////////////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////

import ActiveFeature from './active-feature'

import {
  getFeatureSettings,
//////////////////////////////////
////////////////
///////////
} from './feature-helpers'

////////////////////////////////
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////
///////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
/////////

let collectPublish
let publishedFeatures = {}
const addedScriptNodes = {}

class Features {
//////////////////
////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///

///////////////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////
///////////////////////////////////
///////
//////
///

///////////////////////////////////////////////////////
//////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////
///

//////////////////////////////////////////////////////
/////////////////////
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////
////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////
///////
//////
/////////////////
///

////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////////////////////////
/////////////
////////////
/////////
///////
//////
///

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////
//////
///

/////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////
///////////////////////////
///////////////////////////////////////////////
///

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
/////////////////////////////////
/////////////////
///////////////////////////////////////
/////////////////////////////////////////
//////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////
/////
///

////////////////////////////////////////////////////////////////////////
//////////////
//////////////////////////////////
//////////////////////////////////////
//////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
//////////
///////
//////
/////////////////
///

////////////////////////////////////////////////
////////////////////
//////////////////////////////////////////////////////////////////

///////////////////////////////////
//////////////////////////////////
////////////////////////////////////////////////////////////////////////////
//////
///

/////////////////////////////////////////////////////////////
////////////////////////////////////
//////////////
/////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////////////////////////
//////////////////////////
/////////
///////

//////////////////////////////////////////////////////
///////////////////////////////////////////////////
////////////////////////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
/////
/////////////////
///

///////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
//////
///

///////////////////////////////////////////////////////////
////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
///////////
/////////

///////////////////////////////////////////////////////////////////////////
///

//////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////
///

//////////////////////////////////////////
///////////////////////////////////////////////////////////
///////////////////////////
//////////////////////////////////////////////////////////
/////

////////////////////////////////////////////////////////////////////
///

/////////////////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////////////////////
//////////////////////////////////////////////////////////////////////////////////
////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////
//////////////////////////////////////////
////////////////////////////////
///

//////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

///////////////////////////////////////////
/////////////////////////
/////////////////////////////
//////////////////////////////////
///////////////////////////////////////////////////////
/////////

//////////////////////////
////////////////////////////////
//////////////////////////////////////////////////////
/////////

///////////////////////////////////////////////////
///////////////////////////////////////////////////////
/////////

////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////
////////////////////////////
///////////
//////////

////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
//////////

/////////////////////////////////////////////////
//////////////////////////////////
///////////////////////////////////
//////////////////////////////////////////////
///////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////
///////////////////////
////////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////
///////////////////////
////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////
/////////////////
///////////////
/////////////
////////////////
//////////
////////

///////////////////////////////////////////////
/////////////////////////
////////////////////////
/////////////////////////////////////////////////////////////////////////////////
/////
/////////////////////////
///
///////////

//////////////////////////////////
//////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
///

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
/////////////////
/////

////////////////////////////////////////////////////////////////////////////////////////
////////////////////
///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////
////////////////////////////////////////////
/////////
//////////////////////
///////
////////////
////////////////////////////////////////////
////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////
///////

//////////
//////////////////////////
/////////////////////
///////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////
/////////////////////////

////////////////////////////////////////////////////////////////////
////////////////////////
////////////////////////////////////////
/////////////////////////////
/////////////////
//////////////////////////////////////////////////////////////////////////////////
//////////////////////////
////////////////////////////////////////////////////////
/////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
//////////////////////////
/////////////
///////////
//////////
////////////////////
///////
/////
///

////////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////
///////
//////
///

/////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
//////////////////////////
///

//////////////////////////////////////////////
/////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////
///////////////////////////////////////////////
/////////////////////////////////////////////
////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////
///////////
/////////////////////////////////////////////////////////////////
/////////
////////
/////
///

//////////////////////////////////////////////////////////
///////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////
/////////////////////////////////////
////////
/////

//////////////////////////////////////////
///////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
///////

/////////////////////////////////////////////////////////////////////
/////
///

//////////////////////////////////////////
/////////////////////////
///
///////////

  static killAll () {
    ActiveFeature.killAll()
  }

///////////////////
  static loadFeaturesRuntime (controller, routeParams) {
    if (window.__publishedFeatures) {
      // only used in layout maker
      window.__publishedFeatures.forEach(name => Features.getActiveFeature(controller, name, false, false))
    } else {
      each(window.__features, (featureClass, name) => {
        Features.getActiveFeature(controller, name, false, false)
      })
    }

    Features.openPage(controller, routeParams)
  }
///////////

  // static getSiteFeatures (siteController) {
  //   const siteFeatures = {}
  //   siteController.getAllPageControllers().forEach(pageController => {
  //     pageController.forEachContentControls(contentControl => {
  //       Features.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
  //         siteFeatures[featureName] = featureInstance
  //       })
  //     })
  //   })
  //   return siteFeatures
  // }

  static openPage (siteController, routeParams) {
    let pageType

    // const siteFeatures = Features.getSiteFeatures(siteController)
    // each(siteFeatures, featureInstance => {
    //   pageType = siteController.getActivePageModel().pageType
    //   if (featureInstance.onPageChanged) {
    //     featureInstance.onPageChanged(siteController.getActivePageControl(), pageType || '', routeParams)
    //   }
    // })

    const pageFeatures = {}
    siteController.getActivePageController().forEachContentControls(contentControl => {
      Features.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
        pageFeatures[featureName] = featureInstance
      })
    })

    each(pageFeatures, instance => {
      if ((pageType = siteController.getActivePageModel().pageType) && instance.onOpenPageType) {
        instance.onOpenPageType(siteController.getActivePageControl(), pageType, routeParams)
      }
    })
  }

  static afterRenderCustomHeaderButton (id, element) {
    each(ActiveFeature.getActiveFeatures(false), feature => {
      if (feature.FeatureClass.afterRenderCustomHeaderButton) {
        feature.FeatureClass.afterRenderCustomHeaderButton(id, element)
      }
    })
  }

  static forEachActiveFeatureInstance (callback) {
    each(ActiveFeature.getActiveFeatures(false), active => {
      active.instances.forEach(callback)
    })
  }

  static removeFeaturesForControl (control) {
    if (!control.features) {
      return
    }

    Object.keys(control.features).forEach(key => {
      const activeFeature = Features.getActiveFeature(control.controller, key)

      // Remove all feature instances with this control as a view
      activeFeature.instances.forEach((instance, index) => {
        if (instance.view && instance.view === control) {
          activeFeature.instances.splice(index, 1)
        }

        const idx = activeFeature.featureControlRefs.indexOf(control.features)
        if (idx > -1) {
          activeFeature.featureControlRefs.splice(idx, 1)
        }
        if (activeFeature.instances.length === 0) {
          activeFeature.dispose()
        }
      })
    })
  }

  static getActiveFeature (controller, featureName, isForPreview, isOnboarding, addedFeatureNames, depth = 0) {
    if (depth > 10) {
      console.error('recursion in dependancy tree in features')
      return null
    }

////////////////////
////////////////////////////////////////////////////////////////
/////////////////////////////////////////////
/////
/////////////

    const map = ActiveFeature.getActiveFeatures(isForPreview)

    if (!map[featureName]) {
      const FeatureClass = window.__features && window.__features[featureName]
      if (FeatureClass) {
        let isGlobal = false
        let dependingFeatures = []
        const featureSettings = getFeatureSettings(featureName)
        if (featureSettings && featureSettings.settings) {
          const settings = featureSettings.settings
          isGlobal = settings.isGlobal
          if (settings.dependingGlobalFeatures && Array.isArray(settings.dependingGlobalFeatures)) {
            // load depending features
            dependingFeatures = settings.dependingGlobalFeatures.map(i =>
              Features.getActiveFeature(controller, i, isForPreview, isOnboarding, addedFeatureNames, depth + 1)
            )
          }
        }

        if (addedFeatureNames) {
          addedFeatureNames.push(featureName)
        }
        const activeFeature = new ActiveFeature(
          controller,
          FeatureClass,
          featureName,
          isForPreview,
          isOnboarding,
          dependingFeatures
        )
        if (isGlobal || featureName === 'store-core') {
          activeFeature.getGlobalInstance()
        }
        map[featureName] = activeFeature
      } else {
        // Feature somethimes don't exist in a published site. In the case of legal, it is used in footers but only published if you use this feature. It should return null in this function by design.
        return null
      }
    }
    return map[featureName]
  }

  static constructFeature (featureName, contentControl, isForPreview, addedFeatureNames) {
    const controller = contentControl.controller
////////////////////
///////////////////////////////////////////////////
///////////////////
//////////////////////////////////////////////////////////
/////

////////////////////////////////////////////////////////////////////////////////////////////////
////////////
/////
/////////////

    const activeFeature = Features.getActiveFeature(
      controller,
      featureName,
      isForPreview,
      Features.getIsOnboarding(),
      addedFeatureNames
    )
    if (activeFeature) {
      return activeFeature.createInstance(contentControl)
    }
  }

  static internalLoopFeatureInstances (control, callback, onlyNew = false) {
    if (!control.features) {
      control.features = {}
    }

    const model = control.model
    let isForPreview = false

    let features = model.features // runtime we get the features like this
    if (!model.features) {
//////////////////////
/////////////////////////////////////////////////////////
//////////////
///////
///////////////
      const sectionController = control.controller.getSectionController()
      let sectionLayout
      try {
        sectionLayout = sectionController.getSectionLayoutObj()
      } catch (ex) {
        console.error('Could not load layout', ex)
      }
      isForPreview = sectionController.model._isForPreview
      features = sectionLayout && sectionLayout.metadata && sectionLayout.metadata.features
    }

    if (features) {
      for (let i = 0; i < features.length; i++) {
        const featureName = features[i]

        let featureInstance = control.features[featureName]
        if (!featureInstance) {
          // initialize
          featureInstance = this.constructFeature(featureName, control, isForPreview)
          if (featureInstance) {
            // moved to activeFeature : control.features[featureName] = featureInstance
            if (onlyNew) {
              callback(featureName, featureInstance)
            }
          }
        }

        if (featureInstance && !onlyNew) {
          callback(featureName, featureInstance)
        }
      }
    }
  }

  static loopFeatureInstances (control, callback) {
    if (!control.features) {
      Features.internalLoopFeatureInstances(control, callback)
    } else {
      for (const name in control.features) {
        callback(name, control.features[name])
      }
    }
  }

  static getRenderModel (contentControl, model, globalBinding) {
    let result
    Features.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
      if (featureInstance.getRenderModel) {
        const isForPreview = contentControl.controller.getSectionController().model._isForPreview
        result = {
          ...result,
          ...featureInstance.getRenderModel(contentControl, model, globalBinding, isForPreview)
        }
      }
    })
    return result
  }

  static afterRender (contentControl, data, element) {
    Features.loopFeatureInstances(contentControl, (featureName, featureInstance) => {
      // rerender
      if (featureInstance.afterRender) {
        const wait = (featureInstance.getLoadPromise && featureInstance.getLoadPromise()) || Promise.resolve()
        wait.then(_ => {
          try {
            featureInstance.afterRender(contentControl, element, data)
          } catch (ex) {
            console.error(`afterRender failed of ${featureName}\n` + ex)
          }
        })
      }
    })
  }

  static initializeFeatures (contentControl, data) {
    try {
      Features.internalLoopFeatureInstances(
        contentControl,
        (featureName, featureInstance) => {
          if (featureInstance.initializeForSection) {
            const wait = (featureInstance.getLoadPromise && featureInstance.getLoadPromise()) || Promise.resolve()
            wait.then(_ => {
              const isForPreview = contentControl.controller.getSectionController().model._isForPreview
              featureInstance.initializeForSection(contentControl, data, isForPreview)
            })
          }

          if (featureInstance.updateProperty) {
            contentControl.updateFeatureProperty = featureInstance.updateProperty.bind(featureInstance)
          }
        },
        true
      )
    } catch (ex) {
      console.error('error initializing features', ex)
    }
  }

  static getIsOnboarding () {
////////////////////
/////////////////////////////////////////
////////////
    return false // eslint-disable-line
/////////////
  }
}

export default Features
